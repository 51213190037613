import React, { Component } from 'react'
import { css } from 'emotion'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Layout as _Layout, Text, Color } from '../constants'

export const PrivacyPageTemplate = ({ content, contentComponent, title }) => {
  const MarkDown = contentComponent || Content

  return (
    <section className={css(styles.container)}>
      <div className="container content">
        <h1 className="has-text-weight-bold is-size-2">{title}</h1>
        <MarkDown content={content} />
      </div>
    </section>
  )
}

const PrivacyPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter, html },
    navbarData,
    footerData,
  } = data

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <PrivacyPageTemplate
        title={frontmatter.title}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default PrivacyPage

export const privacyQuery = graphql`
  query PrivacyPage($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`

const styles = {
  container: {
    paddingTop: 80,
    paddingBottom: _Layout.EDGE_PADDING,
    paddingLeft: _Layout.EDGE_PADDING * 1.5,
    paddingRight: _Layout.EDGE_PADDING * 1.5,
  },
}
